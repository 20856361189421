<template>
  <div>
    <h3 class="page-title">ئېلان باشقۇرۇش (باشبەت ئايلانما رەسىم )</h3>
    <el-divider></el-divider>
    <el-row class="rtl-right">
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">قوشۇش </el-button>
    </el-row>
    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
        :data="tableData" 
        border 
        style="width: 100%" 
        v-loading="loading">
        <el-table-column prop="image" label="رەسىم" width="180">
          <template slot-scope="scope">
            <img :src="scope.row.image" class="table-img">
          </template>
        </el-table-column>
        <el-table-column prop="advertable_type" label="تۈرى">
          <template slot-scope="scope">
            <label v-show="scope.row.advertable_type=='teachers'">ئوقۇتقۇچى تەپسىلاتى</label>
            <label v-show="scope.row.advertable_type=='courses'">دەرس تەپسىلاتى</label>
          </template>
        </el-table-column>
        <el-table-column prop="advertable.name_ug" label="ئوبيېكت"></el-table-column>
        <el-table-column prop="order" label="تەرتىپى"></el-table-column>
        <el-table-column prop="start_time" label="باشلىنىش ۋاقتى"></el-table-column>
        <el-table-column prop="end_time" label="ئاخىرلىنىش ۋاقتى"></el-table-column>
        <el-table-column prop="status" width="120" label="ھالەت">
          <template slot-scope="scope">
            <label v-show="scope.row.status==1">ئوچۇق</label>
            <label v-show="scope.row.status==0">ئېتىك</label>
          </template>
        </el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="pageTags.update"
              @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="pageTags.delete"
              @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
          </template>
        </el-table-column>
      </el-table> 
    </div>

    <!-- el-dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="40%"
      top="5vh"
      :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="رەسىم" prop="image">
            <el-upload
              class="avatar-uploader"
              :action="imgActionUrl"
              name="image"
              :data="imageData"
              :headers="imageHeader"
              :on-error="handleImageError"
              :on-success="handleImageSuccess">
              <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="ئېلان تۈرى" prop="advertable_type">
            <el-row>
              <el-col :span="8">
                <label class="choise-tip" v-if="ruleForm.advertable_type == ''">(ئاۋۋال تۈر تاللاڭ)</label>
                <el-button type="success" plain v-if="ruleForm.advertable_type == 'teachers'" @click="showTeachers">ئوقۇتقۇچى تاللاش</el-button>
                <el-button type="success" plain v-if="ruleForm.advertable_type == 'courses'" @click="showCourses">دەرس تاللاش</el-button>
              </el-col>
              <el-col :span="16">
                <el-select v-model="ruleForm.advertable_type" class="rtl-right" filterable clearable placeholder="ئېلان تۈرى">
                  <el-option
                    v-for="item in advertableTypeList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="تاللىغان ئوقۇتقۇچى" v-if="ruleForm.advertable_type == 'teachers'">
            <p class="cur-text">{{curTeacher.name_ug}}</p>
          </el-form-item>
          <el-form-item label="تاللىغان دەرس" v-if="ruleForm.advertable_type == 'courses'">
            <p class="cur-text">{{curCourse.name_ug}}</p>
          </el-form-item>
          <el-form-item label="ئادرېس" prop="url">
            <el-input v-model="ruleForm.url" class="ltr-left" placeholder="ئادرېس"></el-input>
          </el-form-item>
          <el-form-item label="ئېلان ئىسمى" prop="name">
            <el-input v-model="ruleForm.name" placeholder="ئېلان ئىسمى"></el-input>
          </el-form-item>
          <el-form-item label="تەرتىپى" prop="order">
            <el-input v-model="ruleForm.order" class="ltr-left" placeholder="تەرتىپى"></el-input>
          </el-form-item>
          <el-form-item label="ۋاقىت دائىرىسى" prop="timeRange">
            <el-date-picker
              style="width:100%"
              class="ltr-left"
              v-model="ruleForm.timeRange"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="-"
              :picker-options="pickerOptions"
              unlink-panels
              start-placeholder="باشلىنىش ۋاقتى"
              end-placeholder="ئاخىرلىشىش ۋاقتى">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="ھالەت" prop="status" class="radio-form">
            <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
            <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

    <el-drawer
      title="ئوقۇتقۇچى تاللاش"
      size="50%"
      :visible.sync="teacherDrawer"
      direction="rtl">
      <div class="drawer-table-box">
        <el-table :data="teachersTableData" border style="width: 100%" v-loading="teacherLoding">
          <el-table-column prop="name_ug" label="ئوقۇتقۇچى (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="ئوقۇتقۇچى (خەنزۇچە)"></el-table-column>
          <el-table-column prop="phone" label="تېلېفون"></el-table-column>
          <el-table-column label="مەشغۇلات">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseTeacher(scope.row)">تاللاشنى مۇقىملاش</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <el-drawer
      title="دەرس تاللاش"
      size="50%"
      :visible.sync="courseDrawer"
      direction="rtl">
      <div class="drawer-table-box">
        <el-table :data="coursesTableData" border style="width: 100%" v-loading="courseLoding">
          <el-table-column prop="name_ug" label="دەرس (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="name_zh" label="دەرس (خەنزۇچە)"></el-table-column>
          <el-table-column label="مەشغۇلات">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="confirmChoiseCourse(scope.row)">تاللاشنى مۇقىملاش</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-drawer>

  </div>
</template>

<style lang="scss" scoped>
  .avatar-uploader{
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 300px;
    height: 150px;
    img{
      width: 300px;
      height: 150px;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 300px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .table-img{
    width: 120px;
    height: 80px;
    border: 1px solid #EBEEF5;
  }
  .el-date-editor{
    width: 100%;
  }
  .choise-tip{
    position: relative;
    top: 10px;
    right: 10px;
    color: #E6A23C;
  }
  .drawer-table-box{
    padding: 0 10px;
  }
  .cur-text{
    position: relative;
    top: 10px;
    color: #409EFF;
  }
  .table-img{
    width: 150px;
    height: 80px;
    border: 1px solid #EBEEF5;
  }
</style>

<script>
  var self;
  import baseConfig from '../base.config.js'

  export default {
    activated: function() {
      self = this;
      self.getList();
      self.imgActionUrl = baseConfig.imgActionUrl;
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogVisible: false,
        ruleForm: {
          image:"",
          advertable_type:"",
          name: "",
          url:"",
          order:"",
          timeRange:"",
          status: "1"
        },
        editIndex: -1,
        dialogTitle:"",
        addDialog:true,
        editId:"",
        pageTags:{},
        pickerOptions:{
          disabledDate(time) {
            return time.getTime() < Date.now() - 1000*60*60*24;
          }
        },
        imgActionUrl:"",
        imageData:{
          "folder":"test"
        },
        imageHeader:{
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
          'X-Requested-With': 'XMLHttpRequest'
        },
        advertableTypeList:[
          {
            name:"ئوقۇتقۇچى تەپسىلاتى",
            value:"teachers"
          },
          {
            name:"دەرس تەپسىلاتى",
            value:"courses"
          }
        ],
        curAdvertableType:"",
        curTeacher:{},
        curCourse:{},
        teacherDrawer:false,
        teachersTableData:[],
        teacherLoding:false,
        courseDrawer:false,
        coursesTableData:[],
        courseLoding:false,
        per_page:10,
        current_page:1,
        last_page:0,
        total:0,
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/advert").then(response => {
          if (response.status == 200) {
           self.tableData = response.data.data;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      handleImageSuccess(res, file) {
        self.ruleForm.image = res.data.image;
      },
      handleImageError(err,file){
        var uploadErr = JSON.parse(err.toString().replace('Error:',''));
        self.$message({
          message: uploadErr.message,
          type: 'warning'
        });
      },
      getTeachersList(){
        self.teacherLoding = true;
        self.$fetch("admin/teacher").then(response => {
          if (response.status == 200) {
           self.teachersTableData = response.data.data;
          } else{
            console.log(response.message);
          }
          self.teacherLoding = false;
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.teacherLoding = false;
        });
      },
      getCourses(){
        self.courseLoding = true;
        self.$fetch("admin/course",{
          page:self.current_page,
          per_page:self.per_page
        }).then(response => {
          if (response.status == 200) {
           self.coursesTableData = response.data.data;
           self.total = response.data.meta.total;
           self.current_page = response.data.meta.current_page;
           self.last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }
          self.courseLoding = false;
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.courseLoding = false;
        });
      },
      handleSizeChange(val) {
        self.per_page = val;
        self.getCourses();
      },
      handleCurrentChange(val) {
        self.current_page = val;
        self.getCourses();
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش";
        self.editId = row.id;
        self.ruleForm.image = row.image;
        self.ruleForm.name = row.name;
        self.ruleForm.url = row.url;
        self.ruleForm.order = row.order;
        self.ruleForm.status = row.status + '';
        var tmpArr = [];
        tmpArr[0] = row.start_time;
        tmpArr[1] = row.end_time;
        self.ruleForm.timeRange = tmpArr;
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/advert/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      submitForm(formName) {
        var myData = {
          image: self.ruleForm.image,
          name: self.ruleForm.name,
          url:self.ruleForm.url,
          start_time:self.ruleForm.timeRange[0],
          end_time:self.ruleForm.timeRange[1],
          order:self.ruleForm.order,
          advertable_type:self.ruleForm.advertable_type,
          status: self.ruleForm.status
        }
        if(self.ruleForm.advertable_type == "teachers"){
          myData.advertable_id = self.curTeacher.id;
        }else if(self.ruleForm.advertable_type == "courses"){
          myData.advertable_id = self.curCourse.id;
        }
        console.log(myData);

        if(self.addDialog){
          self.$post('admin/advert',myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/advert/'+self.editId,myData).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          image:"",
          advertable_type:"",
          name: "",
          url:"",
          order:"",
          timeRange:"",
          status: "1"
        }
        self.curCourse = {};
        self.curTeacher = {};
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      },
      showTeachers(){
        self.getTeachersList();
        self.teacherDrawer = true;
      },
      confirmChoiseTeacher(row){
        self.curTeacher = row;
        self.teacherDrawer = false;
      },
      showCourses(){
        self.getCourses();
        self.courseDrawer = true;
      },
      confirmChoiseCourse(row){
        self.curCourse = row;
        self.courseDrawer = false;
      }
    }
  };
</script>
